import { Grid, Paper, styled } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import { Card, CardBody, CardHeader } from "reactstrap";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ContactPage() {
  return (  <Grid container direction="column" spacing={2} xs={12} md={6} >
    <Grid height={300}></Grid>
    <Grid>
    <Card>
      <CardHeader tabIndex="-1">
        Contact Us
      </CardHeader>
      <CardBody>
      <h3>Mail:</h3>
      <address>
        <p>8926 Baltimore Street, #163</p>
        <p>Savage, MD 20763</p>
      </address>
      <h3>Phone:</h3>
      <p>(240) 347-1559</p>
      <h3>Email:</h3>
      <a
        href="mailto:contact@aetech-services.com?subject=Information%20Request"
        target="EmailWindow"
      >
        <span className="email">contact@aetech-services.com</span>
      </a>
      </CardBody>
    </Card>
    </Grid>
    </Grid>
  );
}

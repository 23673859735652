
import { Card, CardBody, CardHeader } from "reactstrap";
import ImageCarousel from "../components/ImageCarousel";
import classes from "./styles.css";
import { List, ListItem, ListItemIcon, Paper, styled } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function MainPage() {
  let carouselImages = [
    {
      src: "images/16867537552_c0d1f56f8e_c.jpg",
      altText: "Slide 3",
      caption: "Slide 3",
    },
    { src: "images/binary-globe.jpg", altText: "Slide 3", caption: "Slide 3" },
    {
      src: "images/WOC/25926827581_47af036c7d_z.jpg",
      altText: "Slide 3",
      caption: "Slide 3",
    },
    {
      src: "images/photo_8459_20081206.jpg",
      altText: "Slide 3",
      caption: "Slide 3",
    },
    {
      src: "images/5331040456_1ea6b957a6_z.jpg",
      altText: "Slide 3",
      caption: "Slide 3",
    },
    {
      src: "images/Boardroom-640_z.jpg",
      altText: "Slide 3",
      caption: "Slide 3",
    },
    {
      src: "images/WOC/25392390163_b8392b85db_z.jpg",
      altText: "Slide 3",
      caption: "Slide 3",
    },
    {
      src: "images/team_meeting-640x368.jpg",
      altText: "Slide 3",
      caption: "Slide 3",
    },
    {
      src: "images/WOC/25392637613_9a9358a0e1_z.jpg",
      altText: "Slide 3",
      caption: "Slide 3",
    }
  ];

  return (
    <>
      <Grid2 container spacing={2} >
          <Item>
            <ImageCarousel items={carouselImages} />
          </Item>
          <Card classes={classes}>
            <Card raised="3">
              <CardHeader>"Technology is our Passion"</CardHeader>
              <CardBody>
                Alexander Engineering Technologies, is premier technology
                consulting firm that specializes in combining innovative
                technical solutions with mature engineering approaches to
                achieve continuous success for our customers. We have a passion
                for technology which drives us to push the envelop of what is
                possible. And by continually striving to achieve execellence in
                the solutions we provide.
              </CardBody>
            </Card>
            <Card>
              <CardHeader>Employee Centered</CardHeader>
              <CardBody>
                At Alexander Engineering Technologies, we believe, without
                highly trained and creative employees, it would be impossible to
                meet our customers needs. So, to that end, employee training and
                career development emphasized to all employees. Additionally, we
                believe that developing the best talent not only creates a "Win
                - Win" between the company and the employee, but also provides
                the best possible talent to serve our customer's needs.
              </CardBody>
            </Card>
            <Card>
              <CardHeader>Client Focused</CardHeader>
              <CardBody>
                Alexander Engineering Technologies' clients our priority.
                Providing the best service and highest quality solutions to our
                clients is our sole focus.
              </CardBody>
            </Card>
            <Card>
              <CardHeader>What We Do</CardHeader>
              <CardBody>
                <p>
                  Alexander Engineering Technologies, Inc. provides strategic
                  technology and real estate consulting, supply chain
                  management/procurement and managed services to commercial, not
                  for profit and government organizations. Our organization
                  utilizes the right people, skills, and technologies to help
                  organizations improve their performance and accomplish their
                  business goals. We have a passion for technology, real estate
                  and property management which drives us to push the envelope
                  of what is possible and continually striving to achieve
                  excellence in the solutions we provide. As a Minority Owned
                  Small Business, Alexander Engineering Technologies is
                  committed to creating value added solutions for our clients
                  and partners. How is Alexander Engineering Technologies
                  different than our competition?
                </p>
                <List>
                  <ListItem>
                    Our People: Executives have over 40 years combined leadership
                    experience in Real Estate and Technology
                  </ListItem>
                  <ListItem>
                    Performance-Based Processes that reduce redundancy and
                    maximize productivity.
                  </ListItem>
                  <ListItem>Client focused solutions with proven results.</ListItem>
                  <ListItem>
                    Client Relationship Management: Follow up and check in
                    processes to ensure high graded client experience.
                  </ListItem>
                  <ListItem>
                    Strategic Mix of Technology and Business: Executives
                    are educated in both technology and business – MBA.
                  </ListItem>
                </List>
              </CardBody>
            </Card>
          </Card>
      </Grid2>
    </>
  );
}

import { ListItem, Paper, styled } from "@mui/material";
import { Card, CardBody, CardHeader, List } from "reactstrap";


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function ServicesPage() {
  return (
    <div>
      <Card>
        <CardHeader className="page-title" tabIndex="-1">
          Capabilities
        </CardHeader>
        <CardBody>
          CORE CAPABILITIES Our technology experts and business professionals
          analyze research, design and implement solutions in the areas of:
          <List sx={{ listStyleType: "disc", pl: 4 }}>
            <ListItem sx={{ display: "list-item" }}>
              Software Engineering
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Technical Project Management
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Web Application Development
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Systems Engineering
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Supply chain management and procurement processes
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Managed Services within our Core Capabilities
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Real Estate and property management.
            </ListItem>
          </List>
          Real Estate Consulting: We provide real estate and technology
          consulting services that focus on the performance of your business.
          Our business practices and management strategies are designed to
          enhance your business in a way that will allow you to stay ahead of
          your competition. Our services include:
          <List>
            <ListItem sx={{ display: "list-item" }}>
              Business inventory and planning Support{" "}
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Procurement sourcing and fulfillment
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Financial/Statistical Analyst
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Property Management Services
            </ListItem>
          </List>
          Technology Consulting: Our Technology Services develop secure
          technology solutions for your business. Our solutions ensure that we
          can effectively deliver the end result to our client on time and to
          their satisfaction. Our services include:
          <List>
            <ListItem sx={{ display: "list-item" }}>
              Software Application & Web, Design & Development{" "}
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Commercial Off the Shelf (COTS) Integration{" "}
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Data Cleansing & Conversion
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Cloud Infrastructure Modernization
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              CI/CD pipeline integration
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Database Design, Development, & Maintenance{" "}
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Architecture Solutions
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Verification & Validation Service
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Property management including accounting, maintenance, and
              marketing.
            </ListItem>
          </List>
          Managed Services Our ability to identify and source much needed
          resources for business allows us to manage a variety of services and
          activities for our clients. Alexander Technology Services, Inc can
          help you in the following areas:
          <List>
            <ListItem sx={{ display: "list-item" }}>
              Application Outsourcing{" "}
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Resource Management{" "}
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Help Desk Services
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Infrastructure Outsourcing
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Property maintenance
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>
              Operational Accounting
            </ListItem>
            <ListItem sx={{ display: "list-item" }}>Marketing</ListItem>
          </List>
        </CardBody>
      </Card>
    </div>
  );
}

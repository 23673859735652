import "./App.css";
import MainPage from "./pages/MainPage";
import ServicesPage from "./pages/ServicesPage";
import PortalPage from "./pages/PortalPage";
import ContactPage from "./pages/ContactPage";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MissionPage from "./pages/MissionPage";
import RootLayout from "./pages/RootLayout";
import ErrorPage from "./pages/ErrorPage";



const defaultPages = [
  {
    id: "p1",
    title: "Home",
    target: "HomePage",
    path: "/",
    element: <MainPage />,
  },
  {
    id: "p2",
    title: "Services",
    target: "ServicesPage",
    path: "/services",
    element: <ServicesPage />,
  },
  {
    id: "p3",
    title: "Mission Statement",
    target: "MissionPage",
    path: "/mission",
    element: <MissionPage />,
  },
  {
    id: "p4",
    title: "Portal",
    target: "PortalPage",
    path: "/portal",
    element: <PortalPage />,
  },
  {
    id: "p5",
    title: "Contact Us",
    target: "ContactPage",
    path: "/contact",
    element: <ContactPage />,
  },
];

const routes = [
  {
    path: "/",
    element: <RootLayout menuPages={defaultPages} />,
    errorElement: <ErrorPage/>,
    children: defaultPages
  },
];

const router = createBrowserRouter(routes);

export default function App() {
  

  return <RouterProvider router={router} />;
}
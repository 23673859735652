import { createTheme } from "@mui/material";
 const AetechThemeLight = createTheme({
  palette: {
    mode: "light",
    background: {
      paper: "#fff",
    },
    text: {
      primary:  "#46505A",
      secondary:"#173A5E",
    },
    action: {
      active: "#001E3C",
    }
  },
  typography: {
    htmlFontSize: 12,
    fontFamily: '"Roboto","Roboto-Slab","carrois-gothic-sc"',
    fontSize: 20,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    fontWeightBold:900
  }
});

export default AetechThemeLight;
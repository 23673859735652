import { Container, Row, Col } from "reactstrap";
import { Outlet, useNavigation } from "react-router";
import SideMenu from "../components/SideMenu";
import TopBar from "../components/TopBar";
import AetechThemeLight from "../components/AetechThemeLight";
import AetechThemeDark from "../components/AetechThemeDark";
import { ThemeProvider } from "@mui/system";



export default function RootLayout(props) {
  const navigation = useNavigation();
  navigation.state = 2;
 
  const { menuPages } = props;

  const drawerWidth = 300;

  let AetechTheme= AetechThemeLight;
  
if (window.matchMedia('(prefers-color-scheme: dark)')?.matches  || window.matchMedia('(prefers-dark-interface)') ) {
    console.log("apply styles for OS Dark theme  ")  
    AetechTheme= AetechThemeDark;
}

if (window.matchMedia('(prefers-color-scheme: light)')?.matches  || !window.matchMedia('(prefers-dark-interface)') ) {
  console.log("apply styles for OS Light theme  ")
}

  return (
    <ThemeProvider theme={AetechTheme}>
      <Container>
        <Row>
          <Col>
            <SideMenu items={menuPages} width={drawerWidth} />
          </Col>
          <Col>
            <Row style={{ height: "30%" }}>
                <TopBar drawerWidth={drawerWidth} />
            </Row>
            <Row>
              <Container fluid>
                <Outlet />
              </Container>
            </Row>
          </Col>
        </Row>
      </Container>
    </ThemeProvider>
  );
}

import {
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  styled,
} from "@mui/material";

import { Link } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));

export default function SideMenu(props) {
  const { drawerWidth } = props;

  const navHandler = (event)=>{
    console.log("click"); 
  }

  const links = props.items.map((page) => (
    <ListItem key={page.title} disablePadding>
        <Link to={page.path} underline="none">
      <ListItemButton onClick={navHandler}>
        <ListItemText primary={page.title}></ListItemText>
      </ListItemButton>
        </Link> 
    </ListItem>
  ));

  return (
    <Box sx={{ flexGrow: 1 ,color:'primary.main', bgcolor: 'primary.main'}}>
      <Drawer
        sx={{
          width: '${drawerWidth}',
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Divider />
        <List>{links}</List>
        <Divider />
      </Drawer>
    </Box>
  );
}

import {  Grid, Paper, styled } from "@mui/material";
import { Card, CardBody, CardHeader, CardText, CardTitle } from "reactstrap";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor:  "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));


const TitleTheme = styled(Paper)(({ theme }) => ({
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  // color: theme.palette.text.secondary,
}));


export default function MissionPage() {
  return (
    <Grid container direction="column" spacing={2} xs={12}>
      <Grid height={300}></Grid>
      <Grid>
          <Card>
            <CardHeader>
              <CardTitle className="page-title" tabIndex="-1" sx={{textAlign: "center"}}>
                Our Mission
              </CardTitle>
            </CardHeader>
            <CardBody>
              <CardText>
                At Alexander Engineering Technologies, Inc. (AETech Services)
                our mission is to provide high quality engineering, technical,
                and management support. In order to help our customers overcome
                their technical challenges and meet their mission goals and
                objectives.
              </CardText>
            </CardBody>
          </Card>
      </Grid>
    </Grid>
  );
}

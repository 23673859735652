import { ErrorRounded } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Card, CardBody, CardFooter, CardText, CardTitle, Container } from "reactstrap";

export default function EroorPage(props) {
  return (
    <Container>
      <Card>
        <CardTitle>
          <ErrorRounded /> Error
        </CardTitle>
        <CardBody>
          <CardText>Page Not Found!</CardText>
        </CardBody><CardFooter><Link to='/'><CardText>Home</CardText></Link></CardFooter>
      </Card>
    </Container>
  );
}

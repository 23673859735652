import { Typography, AppBar, Toolbar, Paper, styled } from "@mui/material";

import "./TopBar.css";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "left",
  color: theme.palette.text.secondary,
  background: 'images/black_aluminum_texture-320x209.jpg', 
}));

export default function TopBar(props) {
  const drawerWidth = props.drawerWidth;

  return (
    <AppBar
      className="topBar"
      position="fixed"
      sx={{
        width: `calc(100% - ${drawerWidth / 2}px)`,
        ml: `${drawerWidth}px`,
      }}
    >
      <Toolbar sx={{ height: "30%" }}>
        <Typography variant="h3" noWrap component="div">
          <img height="50%"
            src="images\AE Tech Services Logo-Full Light clear.png"
            alt="Alexander Engineering Technologies, Inc. logo"
          />
        </Typography>
      </Toolbar>
    </AppBar>
  );
}

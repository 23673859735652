import { createTheme } from "@mui/material";

 const AetechThemeDark = createTheme({
  palette: {
    mode: "dark",
    background: {
      paper: "#222",
    },
    text: {
      primary: "#173A5E",
      secondary: "#46505A",
    },
    action: {
      active: "#001E3C",
    }
  },
  typography: {
    htmlFontSize: 12,
    fontFamily: '"Roboto","Roboto-Slab","carrois-gothic-sc"',
    fontSize: 16,
    fontWeightLight: 400,
    fontWeightRegular: 500,
    fontWeightMedium: 700,
    fontWeightBold:900
  }
});

export default AetechThemeDark;